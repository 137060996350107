<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <PacienteInfo v-model="pacienteId" />
                </div>
                <div class="darmin card" v-if="pacienteId && !item?.cita">
                    <div class="card-header pb-0 d-flex">
                        <h5>Citas Programadas</h5>
                    </div>
                    <div class="card-body">
                        <CitasHistoryCard :citas="citas" v-model="citaId" @onSetDoctor="handleSetDoctor" />
                    </div>
                </div>
                <div class="darmin card" v-if="pacienteId && !item?.formType">
                    <div class="card-header">
                        <h5> Especialidad</h5>
                    </div>
                    <div class="card-body">
                        <TypeFormList v-model="formType" />
                    </div>
                </div>
                <div class="darmin card header-card fixed-row">
                    <div class="row">
                        <button type="button" class="btn btn-primary" @click="handleCrearHistoria"
                            :disabled="citaId === '' || formType === ''">Crear
                            Historia</button>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>

<script setup>
import {
    onMounted, defineProps, defineEmits, ref, watch,
} from 'vue';
import { useStore } from 'vuex';
import WindowLayout from '@/layouts/Window.layout.vue';
import PacienteInfo from '../Pacientes/components/PacienteInfo.vue';
import CitasHistoryCard from '../Citas/components/CitasHistoryCard.vue';
import TypeFormList from './components/TypeFormList.vue';

import useConsulta from './services/useConsulta';
import { useCita } from '../Citas/services/useCita';

const props = defineProps({
    title: { type: String, default: 'Crear Consulta' },
    headerTitle: { type: String, default: 'Nueva consulta' },
    item: { type: Object, required: false },
    action: { required: false },
});

const emit = defineEmits(['onClose']);

const store = useStore();
const { getConsulta } = useConsulta();
const { citas, getCitas } = useCita();

const pacienteId = ref('');
const formType = ref('');
const citaId = ref('');
const doctorId = ref('');

onMounted(async () => {
    if (props.item?.cita) {
        // Abrir la ventana dela consulta enviando la cita, cuando se crear el backend tiene que actualizar esa cita con el id de esa consulta
        switch (props.item.formType) {
            case 'VUE_APP_OFTALMOLOGIA_TYPE':
                store.dispatch('ContentManager/openWindow', {
                    id: `ConsultaOftalmologiaForm_${Date.now()}`,
                    component: 'ConsultaOftalmologiaForm',
                    name: 'Nueva Consulta',
                    params: {
                        name: 'Nueva Consulta',
                        headerTitle: 'Nueva Consulta',
                        item: { ...props.item, citaId: props.item.cita },
                    },
                });
                emit('onClose');
                break;
            case 'VUE_APP_GASTROENTEROLOGIA_TYPE':
                store.dispatch('ContentManager/openWindow', {
                    id: `ConsultaGastroenterologiaForm_${Date.now()}`,
                    component: 'ConsultaGastroenterologiaForm',
                    name: 'Nueva Consulta',
                    params: {
                        name: 'Nueva Consulta',
                        headerTitle: 'Nueva Consulta',
                        item: { ...props.item, citaId: props.item.cita },
                    },
                });
                emit('onClose');
                break;
            default:
                break;
        }
    } else if (props.item?.id) {
        const consulta = await getConsulta(props.item?.id);
        switch (consulta.formType) {
            case 'VUE_APP_OFTALMOLOGIA_TYPE':
                store.dispatch('ContentManager/openWindow', {
                    id: `ConsultaOftalmologiaForm_${props.item.id}`,
                    component: 'ConsultaOftalmologiaForm',
                    name: `Consulta ${consulta.paciente.fullName}`,
                    params: {
                        name: 'name',
                        headerTitle: 'headerTitle',
                        item: { ...consulta },
                    },
                });
                emit('onClose');
                break;
            case 'VUE_APP_GASTROENTEROLOGIA_TYPE':
                store.dispatch('ContentManager/openWindow', {
                    id: `ConsultaGastroenterologiaForm_${props.item.id}`,
                    component: 'ConsultaGastroenterologiaForm',
                    name: `Consulta ${consulta.paciente.fullName}`,
                    params: {
                        name: 'name',
                        headerTitle: 'headerTitle',
                        item: { ...consulta },
                    },
                });
                emit('onClose');
                break;
            default:
                break;
        }
    } else {
        // Cargar consulta por paciente: buscar todas las citas primera vez de un pacinente sin consulta y listarlas para escoger, cuando la escoge hace lo que hace el if en true
        // Escoger una cita agendada: busca las citas agendadas de hoy (dejando el selecto de fecha) para escoger alguna
        // Crear la consulta sin cita: se va la vuelta sin cita y no pasa ne

    }

    /** Vamos a mostar el asistente */
    if (props.item.paciente.id) {
        pacienteId.value = props.item.paciente.id;
    }
    if (props.item.formType) {
        formType.value = props.item.formType;
    }
});

watch(() => pacienteId.value, (n) => {
    if (n) {
        // Buscar Citas
        getCitas(n);
    }
});

const handleSetDoctor = (value) => {
    doctorId.value = value;
};

const handleCrearHistoria = () => {
    if (citaId.value) {
        switch (formType.value) {
            case 'VUE_APP_OFTALMOLOGIA_TYPE':
                store.dispatch('ContentManager/openWindow', {
                    id: `ConsultaOftalmologiaForm_${Date.now()}`,
                    component: 'ConsultaOftalmologiaForm',
                    name: 'Nueva Consulta',
                    params: {
                        name: 'Nueva Consulta',
                        headerTitle: 'Nueva Consulta',
                        item: {
                            ...props.item,
                            citaId: citaId.value,
                            paciente: { id: pacienteId.value },
                            doctor: [doctorId.value]
                        },
                    },
                });
                emit('onClose');
                break;
            case 'VUE_APP_GASTROENTEROLOGIA_TYPE':
                store.dispatch('ContentManager/openWindow', {
                    id: `ConsultaGastroenterologiaForm_${Date.now()}`,
                    component: 'ConsultaGastroenterologiaForm',
                    name: 'Nueva Consulta',
                    params: {
                        name: 'Nueva Consulta',
                        headerTitle: 'Nueva Consulta',
                        item: {
                            ...props.item,
                            citaId: citaId.value,
                            paciente: { id: pacienteId.value },
                            doctor: [doctorId.value]
                        },
                    },
                });
                emit('onClose');
                break;
            default:
                break;
        }
    }
};
</script>

<style scoped></style>
