<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th>Fecha</th>
                    <th>Motivo</th>
                    <th>Estado</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="c in citasFiltered" :key="c.id">
                    <td>
                        <input type="radio" :id="c.id" :value="c.id" :name="c.paciente.id" v-model="model"
                            @click="$emit('onSetDoctor', c.especialista)" />
                    </td>
                    <td>
                        <DateTimeColumn :value="c.timeStart" />
                    </td>
                    <td>{{ c.motivo.name }}</td>
                    <td>
                        <CitaStatus :value="c.status" />
                    </td>
                    <!-- <td>
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { defineProps, defineModel, computed } from 'vue';
import DateTimeColumn from '@/components/list/components/DateTimeColumn.vue';
import CitaStatus from './CitaStatus.vue';

const model = defineModel({ default: '' });

const props = defineProps({
    citas: Array
});

const citasFiltered = computed(() => props.citas.filter((c) => !c.control && c.status > 0));
</script>

<style scoped lang="scss">
tbody tr:hover * {
    background-color: var(--background-hover);
}
</style>
