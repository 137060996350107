<template>
    <div class="table">
        <table class="table">
            <tbody>
                <tr>
                    <td>
                        <input type="radio" :name="`formType_${rand}`" id="VUE_APP_OFTALMOLOGIA_TYPE"
                            value="VUE_APP_OFTALMOLOGIA_TYPE" v-model="model">
                    </td>
                    <td>
                        Oftamología - Optometría
                    </td>
                    <td></td>
                    <td></td>

                </tr>
                <tr>
                    <td>
                        <input type="radio" :name="`formType_${rand}`" id="VUE_APP_GASTROENTEROLOGIA_TYPE"
                            value="VUE_APP_GASTROENTEROLOGIA_TYPE" v-model="model">
                    </td>
                    <td>
                        Gastroenterología
                    </td>
                    <td></td>
                    <td></td>
                </tr>

            </tbody>
        </table>
    </div>
</template>

<script setup>
import { ref, onMounted, defineModel } from 'vue';

const rand = ref();
const model = defineModel({ default: '' });

onMounted(() => {
    rand.value = Math.random();
});
</script>

<style scoped lang="scss">
tbody tr:hover * {
    background-color: var(--background-hover);
}
</style>
