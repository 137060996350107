<template>
    <span>{{ date }}</span>
</template>

<script>
import { computed } from 'vue';
import { DateTime } from 'luxon';

export default {
    name: 'DateColumn',
    props: {
        value: { type: String, required: true },
    },
    setup(props) {
        const date = computed(() => DateTime.fromISO(props.value).toFormat('dd LLL yyyy h:mm a', { locale: 'co' }));

        return { date };
    },
};
</script>

<style></style>
